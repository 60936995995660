// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Env$Showside from "./utils/Env.bs.js";
import * as Shopify$Showside from "./utils/Shopify.bs.js";
import * as Caml_js_exceptions from "../../../node_modules/rescript/lib/es6/caml_js_exceptions.js";
import * as EmbedTypes$Showside from "./types/EmbedTypes.bs.js";
import * as CriticalCSS$Showside from "./utils/CriticalCSS.bs.js";
import * as Webapi__Dom__Element from "../../../node_modules/rescript-webapi/lib/es6_global/src/Webapi/Dom/Webapi__Dom__Element.bs.js";
import * as Configuration$Showside from "./types/Configuration.bs.js";
import * as ConfigIdGetter$Showside from "./ConfigIdGetter.bs.js";
async function initSmartnav(index, element) {
  EmbedTypes$Showside.Attributes.autoAssignNamespaces(element);
  var attributes = EmbedTypes$Showside.Attributes.fromElement(element);
  var match = element.id;
  var embedType;
  switch (match) {
    case "maker-smartnav-launcher":
      embedType = /* Launcher */2;
      break;
    case "maker-smartnav-section":
      embedType = /* Section */3;
      break;
    default:
      embedType = /* Bottombar */0;
  }
  var configId = await ConfigIdGetter$Showside.getConfigId(undefined, undefined, attributes, embedType);
  var configId$1;
  if (typeof configId === "number") {
    if (configId !== 0) {
      try {
        var config = await Shopify$Showside.Config.load(Env$Showside.apiHostnameProduction);
        configId$1 = config.id;
      } catch (raw_e) {
        var e = Caml_js_exceptions.internalToOCamlException(raw_e);
        console.error("[Smart Nav] Failed to load Smart Nav config:", e);
        configId$1 = undefined;
      }
    } else {
      configId$1 = undefined;
    }
  } else {
    configId$1 = configId._0;
  }
  if (configId$1 !== undefined) {
    return Configuration$Showside.preloadConfig(undefined, undefined, configId$1);
  }
}
function querySelectorAll(selector) {
  return Belt_Array.reduce(Belt_Array.map(Array.prototype.slice.call(document.querySelectorAll(selector)), Webapi__Dom__Element.ofNode), [], function (acc, curr) {
    if (curr !== undefined) {
      return Belt_Array.concat(acc, [Caml_option.valFromOption(curr)]);
    } else {
      return acc;
    }
  });
}
function getElements(param) {
  var elements = querySelectorAll("#maker-smartnav, #maker-smartnav-launcher, #maker-smartnav-section");
  var makerPagesModal = document.querySelector("#maker-modal");
  var shop = Shopify$Showside.getShop(undefined);
  var match = elements.length !== 0;
  if (match) {
    return elements;
  }
  if (shop === undefined) {
    return [];
  }
  if (!(makerPagesModal == null)) {
    return [];
  }
  var element = document.createElement("div");
  element.id = "maker-smartnav";
  Belt_Option.forEach(Caml_option.nullable_to_opt(document.querySelector("body")), function (child) {
    element.appendChild(child);
  });
  return [element];
}
function init(param) {
  var elements = getElements(undefined);
  if (elements.length !== 0) {
    Belt_Array.mapWithIndex(elements, initSmartnav);
    CriticalCSS$Showside.insertCSS(undefined, undefined);
    import('./types/ConfigurationDecoder.bs.js');
    import('./utils/PictureInPicture2.bs.js');
    import('./utils/Slider.bs.js');
    import('./pages/QuickViewOverlay.bs.js');
    return import('./Entrypoint.bs.js').then(entrypoint => {
      entrypoint.init();
      window.SmartNav = entrypoint;
    });
  } else {
    console.warn("Could not initialize SmartNav: no element found");
    return;
  }
}
function goTo(quickView, quickNav, replaceState, preserveScroll, namespace) {
  import('./Entrypoint.bs.js').then(entrypoint => {
    entrypoint.goTo(quickView, quickNav, replaceState, preserveScroll, namespace);
  });
}
function render(configId, onOpenLink, onAddToCart, trackers, isLauncher, element, attributes) {
  import('./Entrypoint.bs.js').then(entrypoint => {
    entrypoint.render(configId, onOpenLink, onAddToCart, trackers, isLauncher, element, attributes);
  });
}
export { initSmartnav, querySelectorAll, getElements, init, goTo, render };
/* Env-Showside Not a pure module */